import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import {
    CertificateType,
    EEarlyRepaymentStatus,
    EarlyRepaymentStatusColors,
    PriceSource,
    ProfileType,
    SettlementsType,
    getInitialFile,
} from '@dltru/dfa-models'
import { Box, PageHeader, Tag, getDateHelper } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { earlyRepaymentActions } from '@store/earlyRepayment'
import { earlyRepaymentSelector } from '@store/earlyRepayment/selectors'
import { profileSelector } from '@store/profiles/details/selectors'


import { ConfirmByInvestorModal } from './ConfirmByInvestorModal/ConfirmByInvestorModal'
import { DfaInfo } from './DfaInfo/DfaInfo'
import { EarlyRepaymentForm } from './EarlyRepaymentForm/EarlyRepaymentForm'
import { Footer } from './Footer/Footer'
import { TFooterType } from './Footer/types'
import { RepaymentModal } from './RepaymentModal/RepaymentModal'
import { TFormValue } from './types'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

dayjs.extend(isSameOrBefore)

export const EarlyRepayment = () => {
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()

    const { repayment } = useParams()

    const fee = useSelector(earlyRepaymentSelector.selectFee)
    const application = useSelector(earlyRepaymentSelector.selectCurrentApplication)
    const uuid = useSelector(authSelector.selectUserUid)
    const isEmitter = application?.asset.emitter_id === uuid

    const [type, setType] = useState<TFooterType | null>(null)
    const [isFooterDisabled, setIsFooterDisabled] = useState(false)
    const [isModalShow, setIsModalShow] = useState(false)
    const [values, setValues] = useState<Partial<TFormValue>>({})
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)

    const isPriceDynamic = application?.asset?.price_source_type === PriceSource.dynamic
    const tagProps =
        application?.request.status && EarlyRepaymentStatusColors[application?.request.status]
    const subTitle = ''
    const profileTypes = useSelector(profileSelector.selectProfileTypes)
    const isUserPRSN = Boolean(profileTypes?.includes(ProfileType.PRSN))

    const onFooterClickHandler = useCallback(() => {
        setIsModalShow(true)
    }, [])

    const onModalCancelHandler = useCallback(() => {
        setIsModalShow(false)
    }, [])

    const onSubscribe = useCallback(
        (cert: CertificateType) => {
            if (repayment && fee !== null && fee !== undefined) {
                const payload = {
                    request_uuid: repayment,
                    skid: cert.skid,
                    fee,
                }
                if (type === 'confirm') {
                    reduxDispatch(earlyRepaymentActions.confirmByInvestorPrefly(payload))
                }

                if (type === 'repayment') {
                    reduxDispatch(earlyRepaymentActions.confirmByIssuerPrefly(payload))
                }
            }

            setIsModalShow(false)
        },
        [repayment, fee, type],
    )

    const onSign = useCallback(
        (cert: CertificateType) => {
            if (repayment && fee !== null && fee !== undefined) {
                const payload = {
                    request_uuid: repayment,
                    skid: cert.skid,
                    fee,
                }
                if (type === 'confirm') {
                    reduxDispatch(earlyRepaymentActions.confirmByInvestor(payload))
                }

                if (type === 'repayment') {
                    reduxDispatch(earlyRepaymentActions.confirmByIssuer(payload))
                }
            }

            setIsModalShow(false)
        },
        [repayment, fee, type],
    )

    useEffect(() => {
        if (
            application?.asset &&
            application.request.amount &&
            fee !== null &&
            fee !== undefined &&
            application.asset.period_for_early_repayment !== undefined
        ) {
            const total = application.request.amount * (application.asset.redeem_price_per_dfa ?? 0)

            const _values = {
                repaymentDate: getDateHelper(application.asset.redeem_date),
                earlyRepaymentDate: dayjs(application.request.valid_until).format('DD.MM.YYYY'),
                totalAmount: application.request.amount,
                spred: application?.asset.redemption_spread,
                total,
                fee: fee / 100,
                forceRepaymentBase: application.request.force_repayment_base,
                forceRepaymentDocumentUuid: getInitialFile(
                    application.request.force_repayment_document_uuid,
                ),
                meta: application.request.meta,
            }

            setValues(_values)
        }
    }, [application, fee])

    useEffect(() => {
        if (application?.asset && application?.request) {
            let _type: TFooterType | null = null

            if (application?.request.status === EEarlyRepaymentStatus.PLACED && isEmitter) {
                _type = 'repayment'
            }

            if (
                application?.request.status === EEarlyRepaymentStatus.APPROVED &&
                application.request.user_uuid === uuid &&
                application.asset.repayment_settlements_type === SettlementsType.direct
            ) {
                _type = 'confirm'
            }

            setType(_type)

            if (
                _type === 'repayment' &&
                application.asset.period_for_early_repayment !== undefined
            ) {
                const currentDate = dayjs()
                const endDate = dayjs(application.request.created_at).add(
                    application.asset.period_for_early_repayment,
                    'day',
                )
                if (!application.asset.early_repayment_type) {
                    setIsFooterDisabled(endDate.diff(currentDate, 'days') !== 0)
                } else {
                    setIsFooterDisabled(
                        endDate.diff(currentDate, 'days') >
                            application.asset.period_for_early_repayment,
                    )
                }
            }
        }
    }, [application, isEmitter])

    useEffect(() => {
        if (repayment) {
            reduxDispatch(earlyRepaymentActions.getApplicationByUuid(repayment))
            reduxDispatch(earlyRepaymentActions.getFee({ request_uuid: repayment }))
        }
    }, [repayment])

    if (!application?.asset) {
        return null
    }

    const getModalByType = () => {
        if (type === 'confirm') {
            return (
                <ConfirmByInvestorModal
                    onCancel={onModalCancelHandler}
                    onOk={() => setIsVisibleCertificateModal(true)}
                />
            )
        }
        if (type === 'repayment') {
            return (
                <RepaymentModal
                    fee={fee}
                    onCancel={onModalCancelHandler}
                    onOk={() => setIsVisibleCertificateModal(true)}
                    application={application}
                />
            )
        }
    }

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Заявка на досрочное погашение"
                    onBack={() => navigate(-1)}
                    subTitle={subTitle}
                    tags={tagProps ? <Tag color={tagProps.color}>{tagProps.title}</Tag> : undefined}
                />
            </Box>
            <Box
                padding={[0, 24, 24, 24]}
                margin={[0, 0, 8, 0]}
                className="tablebox tablebox--auto-height"
            >
                {application?.asset && application?.request && (
                    <DfaInfo application={application} />
                )}

                {application?.asset && (
                    <Box>
                        <EarlyRepaymentForm
                            price={application?.asset.price_per_dfa}
                            isView={true}
                            isPriceDynamic={isPriceDynamic}
                            amountLimit={application?.asset.balance?.active_amount_dfa || 0}
                            values={values}
                            isEmitter={isEmitter}
                            isUserPRSN={isUserPRSN}
                            paymentCollectType={application?.asset?.payment_collect_type}
                        />
                    </Box>
                )}
            </Box>

            {isModalShow && getModalByType()}

            {isVisibleCertificateModal && (
                <CertificateListModalWithTransactionData
                    isModalVisible={isVisibleCertificateModal}
                    setIsModalVisible={setIsVisibleCertificateModal}
                    onSubscribe={onSubscribe}
                    onSign={onSign}
                />
            )}

            {type && (
                <Footer type={type} onClick={onFooterClickHandler} isDisabled={isFooterDisabled} />
            )}
        </>
    )
}
