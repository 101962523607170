import {
    Button,
    ContentFooterBox,
    CONTENT_FOOTER_ID,
    MoneyOut,
    Portal,
    CheckCircleIcon,
} from '@dltru/dfa-ui'
import { FC } from 'react'
import { IFooterProps } from './types'

export const Footer: FC<IFooterProps> = ({ type, isDisabled, onClick }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox>
                        {type === 'confirm' && (
                            <Button
                                icon={<CheckCircleIcon />}
                                borderRadius={16}
                                type="primary"
                                onClick={onClick}
                                disabled={isDisabled}
                            >
                                Подтвердить погашение ЦФА
                            </Button>
                        )}
                        {type === 'repayment' && (
                            <Button
                                icon={<MoneyOut />}
                                borderRadius={16}
                                type="primary"
                                onClick={onClick}
                                disabled={isDisabled}
                            >
                                Погасить ЦФА
                            </Button>
                        )}
                        {type === 'create' && (
                            <Button
                                borderRadius={16}
                                type="primary"
                                onClick={onClick}
                                disabled={isDisabled}
                            >
                                Направить заявку
                            </Button>
                        )}
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
