import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'

import { PriceSource, ProfileType } from '@dltru/dfa-models'
import { Box, PageHeader, getDateHelper } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { earlyRepaymentActions } from '@store/earlyRepayment'
import { profileSelector } from '@store/profiles/details/selectors'

import { DfaInfo } from './DfaInfo/DfaInfo'
import { EarlyRepaymentForm } from './EarlyRepaymentForm/EarlyRepaymentForm'
import { Footer } from './Footer/Footer'
import { SendModal } from './SendModal/SendModal'
import { TFormValue } from './types'

export const EarlyRepaymentCreate = () => {
    const navigate = useNavigate()
    const reduxDispatch = useDispatch()

    const [isFooterDisabled, setIsFooterDisabled] = useState(true)
    const [isModalShow, setIsModalShow] = useState(false)
    const [values, setValues] = useState<Partial<TFormValue>>({})

    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isPriceDynamic = dfaDetails.price_source_type === PriceSource.dynamic
    const userUuid = useSelector(authSelector.selectUserUid)
    const userFullName = useSelector(profileSelector.userFullName)
    const profileTypes = useSelector(profileSelector.selectProfileTypes)
    const isUserPRSN = Boolean(profileTypes?.includes(ProfileType.PRSN))

    const onFooterClickHandler = useCallback(() => {
        setIsModalShow(true)
    }, [])

    const onModalOkHandler = useCallback(
        (skid: string) => {
            if (userUuid && values.totalAmount && dfaDetails.id) {
                reduxDispatch(
                    earlyRepaymentActions.createApplicationPrefly({
                        asset_id: dfaDetails.id,
                        amount: Number(values.totalAmount),
                        user_uuid: userUuid,
                        skid,
                    }),
                )

            }
        },
        [dfaDetails, userUuid, values],
    )

    const onModalSignOkHandler = useCallback(
        (skid: string) => {
            setIsModalShow(false)

            if (userUuid && values.totalAmount && dfaDetails.id) {
                reduxDispatch(
                    earlyRepaymentActions.createApplication(
                        skid,
                    ),
                )

                navigate(-1)
            }
        },
        [dfaDetails, userUuid, values],
    )

    const onModalCancelHandler = useCallback(() => {
        setIsModalShow(false)
    }, [])

    const onFormChangeHandler = useCallback((formValues: Partial<TFormValue>) => {
        setValues(formValues)
        setIsFooterDisabled(!formValues.totalAmount)
    }, [])

    const subTitle = ''
    const isView = false

    return (
        <>
            <Box padding={16}>
                <PageHeader
                    title="Заявка на досрочное погашение"
                    onBack={() => navigate(-1)}
                    subTitle={subTitle}
                />
            </Box>
            <Box
                padding={[0, 24, 24, 24]}
                margin={[0, 0, 8, 0]}
                className="tablebox tablebox--auto-height"
            >
                <DfaInfo
                    application={{
                        asset: dfaDetails,
                        request: { user_uuid: userUuid, user_full_name: userFullName || '' },
                        wallet_balance: {
                            active_amount_dfa: dfaDetails.balance?.active_amount_dfa,
                            early_repayment_blocked_amount_dfa:
                                dfaDetails.balance?.early_repayment_blocked_amount_dfa,
                            early_repayment_amount_dfa:
                                dfaDetails.balance?.early_repayment_amount_dfa,
                        },
                    }}
                />

                <Box>
                    {dfaDetails.period_for_early_repayment !== undefined && dfaDetails.redeem_date && (
                        <EarlyRepaymentForm
                            onChange={onFormChangeHandler}
                            price={dfaDetails.redeem_price_per_dfa}
                            isView={isView}
                            isPriceDynamic={isPriceDynamic}
                            amountLimit={dfaDetails.balance?.active_amount_dfa ?? 0}
                            values={{
                                repaymentDate: getDateHelper(dfaDetails.redeem_date),
                                earlyRepaymentDate: dayjs(new Date())
                                    .add(dfaDetails.period_for_early_repayment, 'days')
                                    .format('DD.MM.YYYY'),
                                spred: dfaDetails.redemption_spread,
                            }}
                            isUserPRSN={isUserPRSN}
                            paymentCollectType={dfaDetails.payment_collect_type}
                        />
                    )}
                </Box>
            </Box>

            {isModalShow && <SendModal onCancel={onModalCancelHandler} onOk={onModalOkHandler} onSignOk={onModalSignOkHandler}/>}

            {!isView && (
                <Footer
                    type="create"
                    onClick={onFooterClickHandler}
                    isDisabled={isFooterDisabled}
                />
            )}
        </>
    )
}
