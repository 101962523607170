import { CollectType, roundByEpsilon } from '@dltru/dfa-models'
import { Alert, dfaValidators, Divider, Form, onlyDigits, WrappedInput } from '@dltru/dfa-ui'
import { FC, useCallback } from 'react'
import { TFormValue } from '../types'

import styles from './EarlyRepaymentForm.m.less'
import classNames from 'classnames'
import { UploadInputForm } from '@components/Form/components/UploadInputForm'

interface IEarlyRepaymentFormProps {
    isView: boolean
    isPriceDynamic: boolean
    price?: number
    values: Partial<TFormValue>
    amountLimit: number
    isEmitter?: boolean
    onChange?: (values: Partial<TFormValue>) => void
    isUserPRSN: boolean
    paymentCollectType?: CollectType
}

export const EarlyRepaymentForm: FC<IEarlyRepaymentFormProps> = ({
    isView,
    isPriceDynamic,
    price,
    values,
    amountLimit,
    isEmitter,
    onChange,
    isUserPRSN,
    paymentCollectType
}) => {
    const [form] = Form.useForm<TFormValue>()
    form.setFieldsValue({ ...values })

    const onValuesChangeHandler = useCallback(
        (value: Partial<TFormValue>, allValues: TFormValue) => {
            if (value.totalAmount && price) {
                const newValues = {
                    ...allValues,
                    total: roundByEpsilon(value.totalAmount * price, 2),
                }
                form.setFieldsValue(newValues)
                onChange && onChange(newValues)
            } else {
                const newValues = { ...allValues, total: undefined }
                form.setFieldsValue(newValues)
                onChange && onChange(newValues)
            }
        },
        [],
    )

    return (
        <Form
            form={form}
            layout="horizontal"
            className={styles.formWrapper}
            onValuesChange={onValuesChangeHandler}
        >
            <div className={styles.formWrapper}>
                <div className={styles.formRow}>
                    <WrappedInput label="Дата погашения" name={'repaymentDate'} uneditable={true} />
                </div>
                <div className={styles.formRow}>
                    <WrappedInput
                        label="Дата досрочного погашения"
                        name={'earlyRepaymentDate'}
                        uneditable={true}
                    />
                </div>

                <Divider />

                {isPriceDynamic && (
                    <div className={styles.formRow}>
                        <WrappedInput
                            label="Спред к цене досрочного погашения"
                            name={'spred'}
                            uneditable={true}
                        />
                    </div>
                )}

                <div className={styles.formRow}>
                    <WrappedInput
                        label="Количество ЦФА, ед."
                        name={'totalAmount'}
                        uneditable={isView}
                        required
                        rules={[{ validator: dfaValidators.amount(amountLimit) }]}
                        normalize={onlyDigits}
                    />
                </div>

                <div className={styles.formRow}>
                    <WrappedInput label="Сумма погашения" name={'total'} uneditable={true} />

                    {isPriceDynamic && (
                        <p className={styles.dynamicPriceNote}>
                            Фактическая сумма погашения будет рассчитана в дату принятия настоящей
                            заявки к исполнению
                        </p>
                    )}
                </div>

                {values.fee && isEmitter && (
                    <div className={styles.formRow}>
                        <WrappedInput
                            label="Комиссия за погашение"
                            name={'fee'}
                            uneditable={true}
                        />
                    </div>
                )}
                {Boolean(values.forceRepaymentBase && values.forceRepaymentDocumentUuid) &&
                  <>
                    <Divider />
                    <div className={styles.formRow}>
                      <WrappedInput
                        label="Основание"
                        name={'forceRepaymentBase'}
                        uneditable={true}
                      />
                    </div>
                    <div className={classNames(styles.formRow, styles.file_wrapper)}>
                      <UploadInputForm
                        name="forceRepaymentDocumentUuid"
                        label=""
                        uneditable={true}
                      />
                    </div>
                  </>
                }
                {isEmitter && values.meta?.is_last &&
                  <div className={classNames(styles.formRow, styles.alert)}>
                    <Alert
                      message={`Размер комиссии за данную операцию был пересчитан. Комиссия за погашение всего выпуска ЦФА уплачена полностью.`}
                      type="warning"
                      showIcon
                    />
                  </div>
                }
                {isUserPRSN && paymentCollectType !== CollectType.account &&
                  <div className={classNames(styles.formRow, styles.alert)}>
                    <Alert
                      message="В момент совершения сделки будет удержан НДФЛ, рассчитанный с налоговой базы за текущий налоговый период."
                      type="warning"
                      showIcon
                    />
                  </div>
                }
            </div>
        </Form>
    )
}
